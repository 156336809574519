<template>
  <div class="c2cbg">
    <van-nav-bar  @click-left="onClickLeft" style="height: 30px;padding: 3%;" left-arrow>
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal;color: rgb(234, 236, 239);">成交明細</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="padding: 20px;color: #B3B4B7;background-color: #181a20;">
      <van-row v-for="(item, index) in list" :key="index" style="margin-bottom: 20px;">
              <van-col span="24" style="border: 1px gainsboro solid;padding: 20px;border-radius: 10px;">
                <div style="display: flex;color: #B3B4B7;">
                <div style="font-size: 15px;width: 50%;">
                  <div>单价:{{ item.price }}</div>
                  <div>总价:{{ item.total_price }}</div>
                </div>
                <div style="font-size: 15px;color: #B3B4B7;">
                  <div>成交:{{ item.chengjiao_num }}</div>
                   <div>数量:{{ item.num }}</div>
                </div>
                </div>
                <van-row style="margin-top: 10px;display: flex;">
                  <van-col span="24" style="font-size: 12px;">{{ item.trade_time }}</van-col>
                </van-row>
              </van-col>
      </van-row>
    </div>
    <van-popup v-model:show="show" :style="{ height: '260px',padding: '64px',size:'5px',width:'60%' }" >
     <div style="position: absolute;margin-left: -18%;font-size: 15px;padding: 10px;">
       1.兌換貨幣：支持多主流幣種相互兌換。<br />
       2.手續費：兌換手續費為0.8%雙向收取。<br />
       3.兌換轉換：提供多種數字貨幣之間的兌換選擇，用戶可以根據需求選擇各種數字貨幣之間兌換轉換。<br />
       4.交易執行：鯤鵬DEX融合了dydx協議，運用混合兌換模式，具有兌換執行速度快、無滑點、低費用、無需信任等特點，用戶可以隨時兌換數字貨幣。
     </div>
        <van-button @click="closePopup" style="color: aliceblue;width: 100px;height: 60px;background-color: coral;border-radius: 10px;margin-left: 25%;margin-top: 230px;">已知悉</van-button>
    </van-popup>
  </div>

</template>

<script>
import { getTradeOrder, setTradeOrder,getRocPrice,getTradeOtherInfo } from "@/utils/api.js";
import { Popup, Button, radio, Locale } from 'vant';
import { getUserInfo } from "../../utils/api";
export default {
  data() {
    return {
      itemIndex: 1,
      list: [],
      selllist: [],
      show: false,
      type: '1',
      price: '',
      num: '',
      amount1:0,
      amount2:0,
      user:[],
      balance:[],
    }
  },
  watch: {
      num: function(newValue) {
        this.amount1 = newValue / this.price || 0;
         this.amount2 = newValue * this.price || 0;
      },
    },
  created() {
    this.getlist()
    // this.getrocjiage()
    // this.getuser()
    // this.getuserbalance()
  },
  methods: {
    qiehuan(id) {
      this.type = String(id)
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    addOrder(num) {
      let params = {
          roc_price : this.price ,
          num : this.num ,
          type : 1 ,
      }
      setTradeOrder(params).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
          location.reload();
        }
      })
    },
    closePopup (){
      this.show = false;
    },
    showPopup () {
          this.show = true;
        },
    sellOrder(num) {
      let params = {
          roc_price : this.price ,
          num : this.num ,
          type : 2 ,
      }
      setTradeOrder(params).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
        }
      })
    },
    btnClick(index) {
      this.itemIndex = index
    },
    onClickRight() {
      this.show = true;
    },
    clousePop() {
      this.show = false;
    },
    buy(item) {
      toBuy(item).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
        }
      })
      console.log('submit', item);
    },
    sell(item) {
      toSell(item).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
        }
      })
      console.log('submit', item);
    },
    getrocjiage(){
     getRocPrice().then((res) => {

       this.price = res.data.roc_price
     })
    },
    getuserbalance(){
     getUserInfo().then((res) => {

        this.balance = res.data.user
     })
    },
    getuser(){
     getTradeOtherInfo().then((res) => {

        this.user = res.data
     })
    },
    getlist() {
      let params = {
        page: 1,
        pagesize: 20
      }
      getTradeOrder(params).then((res) => {
        this.list = res.data.list
      })
    }
  }
};

</script>

<style lang="scss" scoped>

  ::v-deep .van-nav-bar__arrow {
        font-size: 46px !important;
        padding-top: 10px;
        color: #fff;
  }
.van-nav-bar__content {
  height: 1rem !important;
  font-size: 44px;
}
.van-haptics-feedback{
  font-size: 44px;
}
::v-deep .van-nav-bar__title {
  font-weight: bold !important;
  font-size: 30px !important;
  height: 80px;
}

::v-deep .van-nav-bar__content {
  padding-top: 30px;
  height: 80px;
  // margin: auto;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

.van-hairline--bottom:after {
  border-bottom-width: 0 !important;
}

.van-collapse-item {
  border: 1px red solid;
}

.van-popup {
  // position: absolute !important;
}

.van-dropdown-menu__title {
  line-height: normal !important;
}

.van-dropdown-menu__bar {
  height: 60px !important;
}

.van-nav-bar__left {
  padding: 0rem 0.3rem 0rem 0.3rem !important;
  font-size: 44px ;
}

.van-ellipsis {
  font-weight: bold !important;
  overflow: visible;
}
.detail{
  background-color: rgb(30, 35, 41);
  width: 85%;
  height: 500px;
  margin-top: 5%;
  margin-left: 8%;
  border-radius: 10px;
}
.c2cbg {
  background-color: #181a20;
  height: 100%;
  width: 100%;
  touch-action: pan-y;
  .tab {
    display: flex;

    .btn {

      margin-left: 5%;
      // font-size: 50px;
    }
  }
}

.active {
  font-weight: bold;
  color: #3E74EE;
  font-size: 36px;
}
</style>

